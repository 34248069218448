<template>
  <div class="container_monitors">
    <b-container class="py-5 px-4 px-md-5">
      <b-row class="mb-3 pt-lg-4">
        <b-col cols="12" class="mb-lg-2 title1">
            <h3 class="fw-bold">New service</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            </p>
          </b-col>
      </b-row>
      <form>
        <!-- Endpoint -->
        <b-row align-h="center" class="mb-5">
          <b-col cols="12" align-self="center">
            <b-card no-body class="py-4 px-md-4">
              <b-container>
                <b-row>
                  <b-col cols="12" md="6">
                    <!-- Name -->
                    <b-form-group label="Endpoint" label-for="endpoint" class="text-start mb-3">
                      <b-form-input id="endpoint" type="text" placeholder="Name"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <!-- Method -->
                    <b-form-group label="Method" label-for="method" class="text-start mb-3">
                      <b-form-select v-model="form.method" :options="method_options" id="method" class="form-select"></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center" class="mb-5">
          <b-col cols="12" align-self="center">
            <b-card no-body class="pb-lg-4">
              <b-tabs pills card>
                <!-- Parameters -->
                <b-tab active>
                  <template #title>
                    <div class="d-flex">
                      <img src="@/assets/img/option.true.png" width="18" height="18" class="me-2 option-true">
                      <img src="@/assets/img/option.false.png" width="18" height="18" class="me-2 option-false">
                      <label>Parameters</label>
                    </div>
                  </template>
                  
                  <b-container class="container-parameters">
                    <b-row align-v="center">
                      <!-- Name -->
                      <b-col cols="12" md="6" lg="3">
                        <b-form-group label="1. Name" label-for="parameter-name" class="text-start mb-3">
                          <b-form-input id="parameter-name" type="text" placeholder="Name"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <!-- Parameter type -->
                      <b-col cols="12" md="6" lg="3">
                        <b-form-group label="Parameter type" label-for="parameter-type" class="text-start mb-3">
                          <b-form-select v-model="form.method" :options="parameter_types" id="parameter-type" class="form-select"></b-form-select>
                        </b-form-group>
                      </b-col>
                      <!-- Parameter value -->
                      <b-col cols="12" md="6" lg="3">
                        <b-form-group label="Parameter value" label-for="parameter-value" class="text-start mb-3">
                          <b-form-input id="parameter-value" type="text" placeholder="Parameter value"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6" lg="2" xl="1" class="p-0 action-buttons">
                        <img src="@/assets/img/trash.png" width="30" height="30" class="me-2">
                        <img src="@/assets/img/plus.png" width="30" height="30" class="plus">
                      </b-col>
                    </b-row>
                  </b-container>
                </b-tab>
                
                <!-- Headers -->
                <b-tab>
                  <template #title>
                    <div class="d-flex">
                      <img src="@/assets/img/option.true.png" width="18" height="18" class="me-2 option-true">
                      <img src="@/assets/img/option.false.png" width="18" height="18" class="me-2 option-false">
                      <label>Headers</label>
                    </div>
                  </template>
                  <b-container>
                    <b-row align-v="center">
                      <!-- Key -->
                      <b-col cols="12" md="5">
                        <b-form-group label="1. Key" label-for="header-key" class="text-start mb-3">
                          <b-form-input id="header-key" type="text" placeholder="Header key"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="5">
                        <!-- Value -->
                        <b-form-group label="Value" label-for="header-value" class="text-start mb-3">
                          <b-form-input id="header-value" type="text" placeholder="Header value"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="auto" lg="2" xl="1" class="p-0 action-buttons">
                        <img src="@/assets/img/trash.png" width="30" height="30" class="me-2">
                        <img src="@/assets/img/plus.png" width="30" height="30" class="plus">
                      </b-col>
                    </b-row>
                  </b-container>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>

        <!-- Expected Values -->
        <b-row align-h="center" class="mb-5">
          <b-col cols="12" align-self="center">
            <b-card no-body class="py-4 px-md-4">
              <b-container>
                <b-row>
                  <b-col cols="12">
                    <h5>Expected Values</h5>
                  </b-col>
                  <!-- Key -->
                  <b-col cols="12" md="6" xl="3">
                    <b-form-group label="Key" label-for="expected-key" class="text-start mb-3">
                      <b-form-input id="expected-key" type="text" placeholder="Key"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <!-- Condition -->
                  <b-col cols="12" md="6" xl="3">
                    <b-form-group label="Condition" label-for="expected-condition" class="text-start mb-3">
                      <b-form-select v-model="form.condition" :options="conditions" id="expected-condition" class="form-select"></b-form-select>
                    </b-form-group>
                  </b-col>
                  <!-- Value -->
                  <b-col cols="12" md="6" xl="3">
                    <b-form-group label="Value" label-for="expected-value" class="text-start mb-3">
                      <b-form-input id="expected-value" type="text" placeholder="Value"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <!-- Global Name -->
                  <b-col class="d-flex" cols="12" md="6" xl="3">
                    <b-form-checkbox id="global-variable" v-model="global"></b-form-checkbox>&nbsp;&nbsp;
                    <b-form-group label="Global Name" label-for="global-name" class="text-start mb-3 w-100" v-if="global">
                      <b-form-input id="global-name" type="text" placeholder="Global Name"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
        </b-row>

        <!-- Config alerts -->
        <b-row align-h="center" class="mb-4 mb-md-5">
          <b-col cols="12" align-self="center">
            <b-card no-body class="py-4 px-md-4">
              <b-container>
                <b-row>
                  <b-col cols="12">
                    <h5>Config alerts</h5>
                  </b-col>
                  <!-- Key -->
                  <b-col cols="12" md="6" xl="3">
                    <b-form-group label="Key" label-for="config-key" class="text-start mb-3">
                      <b-form-input id="config-key" type="text" placeholder="Key"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <!-- Condition -->
                  <b-col cols="12" md="6" xl="3">
                    <b-form-group label="Condition" label-for="config-condition" class="text-start mb-3">
                      <b-form-select v-model="form.condition" :options="conditions" id="config-condition" class="form-select"></b-form-select>
                    </b-form-group>
                  </b-col>
                  <!-- Value -->
                  <b-col cols="12" md="6" xl="3">
                    <b-form-group label="Value" label-for="config-value" class="text-start mb-3">
                      <b-form-input id="config-value" type="text" placeholder="Value"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <!-- Message -->
                  <b-col cols="12" md="6" xl="3">
                    <b-form-group label="Message" label-for="message" class="text-start mb-3 w-100">
                      <b-form-input id="message" type="text" placeholder="Message"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
        </b-row>

        <!-- Button -->
        <div class="d-flex">
          <b-button variant="secondary" type="submit" class="mt-lg-4 px-5 ms-auto">Save</b-button>
        </div>
      </form>
    </b-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          measure: null,
          method: null,
          condition: null,
        },
        method_options: [
          { value: null, text: 'Make a selection' },
          { value: 'Method #1', text: 'Method #1' }
        ],
        parameter_types: [
          { value: null, text: 'Select' },
          { value: 'Parameter #1', text: 'Parameter #1' }
        ],
        conditions: [
          { value: null, text: 'Select' },
          { value: 'is', text: 'IS' },
          { value: '=', text: '=' },
        ],
        parameters: true,
        headers: false,
        global: false,
        load: false,
      }
    },
  }
</script>
